<route>
{
  "meta": {
    "auth": "subMerchantAccountManage"
  }
}
</route>
<template>
  <div>
    <bm-cardTabList :list="tabList" current="二级商户号"></bm-cardTabList>
    <div class="tableContainer">
      <div class="header" style="margin: 20px 0">
        <span class="title">结算账户列表</span>
        <div style="float: right">
          <el-button v-auth="'accountManageAdd'" type="success" @click="miniProgramUpload.show = true"
            >小程序填写</el-button
          >
          <el-button v-auth="'shareSubMerchant'" type="success" @click="handleApply()">取用</el-button>
          <el-button v-auth="'accountManageAdd'" type="success" @click="handleEdit('0')">添加</el-button>
        </div>
      </div>
      <el-table :data="tableData" style="width: 100%" v-loading="loading">
        <el-table-column align="center" prop="accountName" label="账号昵称"> </el-table-column>
        <el-table-column align="center" prop="outRequestNo" label="业务申请编号"> </el-table-column>
        <el-table-column align="center" prop="applymentId" label="微信申请单号"> </el-table-column>
        <el-table-column align="center" prop="remark" label="备注"> </el-table-column>
        <el-table-column align="center" prop="storeCount" label="绑定门店数量"> </el-table-column>
        <el-table-column align="left" prop="storeCount" label="银行账户信息">
          <template slot-scope="scope">
            <div>开户人名称：{{ scope.row.openAccountName }}</div>
            <div>收款银行：{{ scope.row.accountBank }}</div>
            <div>银行账户：{{ scope.row.accountNumber }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="contactName" label="超级管理员"> </el-table-column>
        <el-table-column align="center" prop="applymentState" label="审核状态">
          <template slot-scope="scope">
            <el-button
              @click="handleClickShareBtn(scope.row.subMerchantId)"
              v-if="scope.row.shareStatus"
              size="mini"
              type="primary"
              plain
            >
              取用
            </el-button>
            <el-button v-else-if="scope.row.isBlankApply" size="mini" type="success" plain> 待上传 </el-button>
            <el-button
              handle
              @click="handleUnlock(scope.row.subMerchantId)"
              v-else-if="scope.row.applymentState == 6 && scope.row.lockStatus"
              size="mini"
              type="success"
              plain
            >
              锁定中
            </el-button>
            <el-button
              v-else-if="scope.row.applymentState == 4"
              @click="handleEdit(scope.row.subMerchantId)"
              size="mini"
              type="danger"
              plain
            >
              已驳回
            </el-button>
            <el-button v-else-if="scope.row.applymentState == 6" size="mini" type="success" plain> 完成 </el-button>
            <el-button
              v-else-if="[2, 5].includes(scope.row.applymentState)"
              size="mini"
              type="success"
              plain
              @click="handleShowWxCode(scope.row)"
            >
              <span v-if="scope.row.applymentState === 5">待签约</span>
              <span v-else>待账户验证</span>
            </el-button>

            <el-button v-else size="mini" type="primary" plain>{{
              ['', '资料校验中', '待账户验证', '审核中', '已驳回', '完成', '已冻结'][scope.row.applymentState]
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleAssets(scope.row)">提现 </el-button>
            <div v-if="scope.row.shareStatus === 1"></div>
            <el-button
              v-else-if="scope.row.applymentState === 4"
              v-auth="'accountManageEdit'"
              @click="handleEdit(scope.row.subMerchantId)"
              type="text"
              size="small"
              >编辑资料
            </el-button>
            <el-button
              v-else-if="scope.row.applymentState === 6"
              v-auth="'accountManageEdit'"
              @click="handleEditBankInfo(scope.row.subMerchantId)"
              type="text"
              size="small"
              >编辑银行卡
            </el-button>
            <el-button v-show="scope.row.applymentState !== 6" @click="showQRCode(scope.row)" type="text" size="small"
              >查看链接
            </el-button>
            <el-button @click="handleEditRemark(scope.row)" type="text" size="small">编辑备注 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="wxCodeData.isShow"
      :width="showTransfer ? '850px' : '280px'"
      :title="showTransfer ? '待验证账号(请在下面两种验证方式中选择其中一种验证)' : '微信验证'"
    >
      <h3 style="text-align: center; margin-bottom: 20px">
        {{ showTransfer ? '待验证账号(请在下面两种验证方式中选择其中一种验证)' : '' }}
      </h3>
      <div class="accountValidation" v-if="showTransfer">
        <h3 style="margin: 20px; text-align: center">{{ showTransfer ? '①' : '' }}汇款验证</h3>
        <div class="item" v-for="(item, index) in Object.keys(accountValidation.label)" :key="index">
          <div class="des">{{ accountValidation.label[item] }}</div>
          <div>{{ accountValidation.value[item] }}</div>
        </div>

        <div style="color: red; font-size: 18px; text-align: center">
          请商家汇指定金额到指定银行账号，进行入驻账户验证
        </div>
      </div>
      <div class="wxCodeDataContainer" :style="showTransfer ? '' : ' width: 100%;'">
        <h3 style="margin: 20px">{{ showTransfer ? '②' : '' }}微信验证</h3>
        <div v-if="tip">暂无微信二维码</div>
        <canvas ref="canvas"></canvas>
        <div v-if="wxCodeData.applymentState === 5">请使用商户号绑定的管理员微信扫码</div>
        <div v-else>请使用法人绑定的微信扫码</div>
      </div>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" :visible.sync="apply.show" title="共享二级商户号">
      <el-input style="margin: 10px 0" placeholder="请输入业务申请编号" v-model="apply.id" class="input-with-select">
        <template slot="prepend">业务申请编号</template>
        <el-button @click="searchOutRequestNo" slot="append" icon="el-icon-search"></el-button>
      </el-input>

      <el-table :data="apply.list" ref="multipleTable" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column align="center" prop="accountName" label="账号昵称"> </el-table-column>
        <el-table-column align="center" prop="outRequestNo" label="业务申请编号"> </el-table-column>

        <el-table-column align="left" prop="storeCount" label="银行账户信息">
          <template slot-scope="scope">
            <div>开户人名称：{{ scope.row.openAccountName }}</div>
            <div>收款银行：{{ scope.row.accountBank }}</div>
            <div>银行账户：{{ scope.row.accountNumber }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="contactName" label="超级管理员"> </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button class="btn outline" @click="apply.show = false">取 消</el-button>
        <el-button class="btn" :disabled="!apply.outRequestNo" type="primary" @click="confirm">确 定</el-button>
      </span></el-dialog
    >

    <el-dialog :visible.sync="miniProgramUpload.show" title="申请二级商户号链接" width="300px">
      <div style="text-align: center">是否生成二级商户号链接</div>
      <div slot="footer">
        <el-button @click="miniProgramUpload.show = false">取 消</el-button>
        <el-button @click="confirmGenerate" type="primary">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="miniProgramUpload.showLock" title="提示" width="300px">
      <div style="text-align: center">是否解锁该二级商户号</div>
      <div slot="footer">
        <el-button @click="miniProgramUpload.showLock = false">取 消</el-button>
        <el-button @click="confirmUnlock" type="primary">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="miniProgramUpload.showCode" title="小程序填写二级商户号链接">
      <div style="text-align: center">
        <canvas ref="miniProgram"></canvas>
      </div>

      <div slot="footer">
        <el-button @click="miniProgramUpload.showCode = false">取 消</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="editData.isShow" title="修改备注" width="300px">
      <el-form class="editContainer">
        <el-form-item label="备注">
          <el-input
            style="width: 200px"
            maxlength="20"
            show-word-limit
            v-model.trim="editData.remark"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="editData.isShow = false">取 消</el-button>
        <el-button @click="submitEdit" type="primary">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="shareBtn.isShow" title="来源商家" width="300px">
      <el-form class="editContainer">
        <el-form-item label="商家名称">
          {{ shareBtn.businessTitle }}
        </el-form-item>
        <el-form-item label="商家ID">
          {{ shareBtn.id }}
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button @click="shareBtn.isShow = false">取 消</el-button>
        <el-button
          @click="
            $router.push({
              path: '/vendor/vendorMange/list',
              query: {
                id: shareBtn.id
              }
            })
          "
          type="primary"
          >查 看</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
import QRCode from 'qrcode'
import { tabListIncludeAccount, tabListExcludeAccount } from '../tabList'
export default {
  mixins: [pagination],
  data() {
    return {
      tip: false,
      tableData: [],
      tabList: [],
      list: [],
      showTransfer: false,
      isShowWxCode: false,
      miniProgramUpload: {
        show: false,
        showCode: false,
        showLock: false,
        id: ''
      },
      wxCodeData: {
        isShow: false,
        url: '',
        applymentState: 0
      },
      editData: {
        isShow: false,
        id: '',
        remark: ''
      },
      shareBtn: { isShow: false, id: '', businessTitle: '' },
      accountValidation: {
        label: {
          accountName: '汇款账号名',
          deadline: '有效时间至',
          destinationAccountBank: '收款账号开户行所在地',
          destinationAccountName: '收款账号开户名',
          destinationAccountNumber: '收款银行账号',
          payAmount: '汇款金额(单位：分)'
        },
        value: {}
      },
      apply: {
        show: false,
        id: '',
        outRequestNo: '',
        list: []
      }
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$store.getters['vendor/params/getBusinessModel'] == 0) {
        vm.tabList = tabListIncludeAccount
      } else {
        vm.tabList = tabListExcludeAccount
      }
    })
  },
  methods: {
    handleClickShareBtn(id) {
      this.showBtn = true
      this.$http
        .get('/boom-center-product-service/sysAdmin/subMerchant/findOriginBusiness/' + id)
        .then(res => {
          this.shareBtn = { ...this.shareBtn, ...res, isShow: true }
        })
        .catch(err => {
          console.log(err.msg)
        })
    },
    handleUnlock(id) {
      this.miniProgramUpload.showLock = true
      this.miniProgramUpload.id = id
    },
    handleEditRemark(val) {
      this.editData = {
        isShow: true,
        id: val.subMerchantId,
        remark: val.remark
      }
    },
    handleAssets(row) {
      this.$store.commit('vendor/params/updateParams', { key: 'accountId', value: row.subMchid })
      this.$router.push({
        path: '/vendor/vendorMange/account/withdraw'
      })
      let obj = {}
      obj.accountBank = row.accountBank
      obj.accountName = row.accountName
      obj.bankAccountType = row.bankAccountType
      obj.accountNumber = row.accountNumber
      sessionStorage.accountInfo = JSON.stringify(obj)
    },
    //添加小程序填写
    confirmGenerate() {
      this.$http
        .post('/boom-center-product-service/sysAdmin/subMerchant/add_blank_apply', {
          businessId: this.$store.getters['vendor/params/getVendorId']
        })
        .then(res => {
          this.getList()
          this.miniProgramUpload.show = false
        })
        .catch(err => {
          this.$message.error('生成码错误')
        })
    },

    //生成跳转小程序二维码
    showQRCode(row) {
      console.log(row)
      this.miniProgramUpload.showCode = true
      let url
      if (this.$store.getters['global/isEnvProd']) {
        url = `https://miniapp.egg.tanchi.shop/businessAccount?outRequestNo=${row.outRequestNo}&subMerchantId=${row.subMerchantId}`
      } else {
        url = `https://miniapp.egg.tanchi0757.shop/businessAccount?outRequestNo=${row.outRequestNo}&subMerchantId=${row.subMerchantId}`
      }
      // let url = 'pageAdmin/secAccount/index' + row.outRequestNo
      this.$nextTick(() => {
        const canvas = this.$refs.miniProgram
        QRCode.toCanvas(canvas, url, function (error) {
          if (error) console.error(error)
          console.log('success!')
        })
      })
    },
    submitEdit() {
      this.$http
        .put('/boom-center-product-service/sysAdmin/subMerchant/updateRemark', {
          remark: this.editData.remark,
          subMerchantId: this.editData.id
        })
        .then(res => {
          this.editData.isShow = false

          this.getList()
        })
    },
    handleSelectionChange(val) {
      this.apply.outRequestNo = ''
      if (val.length !== 0) {
        this.apply.outRequestNo = val[0].outRequestNo
      }
    },
    handleApply() {
      this.apply.show = true
      this.apply.id = ''
    },
    searchOutRequestNo() {
      this.$http
        .get('/boom-center-product-service/sysAdmin/subMerchant/findByOutRequestNo/' + this.apply.id)
        .then(res => {
          this.apply.list = [res]
        })
        .catch(err => {
          this.$message.error(err.msg || '搜索出错')
        })
    },
    confirm() {
      this.$http
        .post('/boom-center-product-service/sysAdmin/subMerchant/shareSubMerchant', {
          businessId: this.$store.getters['vendor/params/getVendorId'],
          outRequestNo: this.apply.outRequestNo
        })
        .then(res => {
          this.getList()
          this.apply.show = false
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    //取消锁定
    confirmUnlock() {
      this.$http
        .put(`/boom-center-product-service/sysAdmin/subMerchant/unlock_apply/${this.miniProgramUpload.id}`)
        .then(res => {
          this.miniProgramUpload.showLock = false
          this.$message({
            message: '取消锁定成功',
            type: 'success'
          })
        })
        .catch(err => {
          this.$message.error(err.msg || '解锁失败')
        })
    },
    handleEditBankInfo(id) {
      this.$store.commit('vendor/params/updateParams', { key: 'accountId', value: id })
      this.$router.push({
        path: '/vendor/vendorMange/account/edit',
        query: {
          target: 'bankInfo'
        }
      })
    },
    handleShowWxCode(val) {
      this.showTransfer = false
      this.$http
        .get(`/boom-center-product-service/sysAdmin/subMerchant/out-request-no/${val.outRequestNo}`)
        .then(res => {
          if (res.accountValidation) {
            this.showTransfer = true
            this.accountValidation.value = res.accountValidation
          }
          this.wxCodeData.isShow = true
          this.wxCodeData.applymentState = val.applymentState
          this.tip = false
          if (!res.signUrl && !res.legalValidationUrl) {
            this.tip = true
            this.$nextTick(() => {
              this.$refs.canvas.height = this.$refs.canvas.height // 重置画布
            })
            return
          }

          this.$nextTick(() => {
            const canvas = this.$refs.canvas
            QRCode.toCanvas(canvas, res.signUrl || res.legalValidationUrl, function (error) {
              if (error) console.error(error)
              console.log('success!')
            })
          })
        })
        .catch(err => {
          this.$message.error(err)
        })
    },

    handleEdit(id = '') {
      this.$store.commit('vendor/params/updateParams', { key: 'accountId', value: id })
      this.$router.push({
        path: '/vendor/vendorMange/account/edit',
        query: {
          target: 'basicInfo'
        }
      })
    },
    getList(val) {
      this.currentPage = val === true ? this.currentPage : 1
      // 判断是否是分页还是普通搜索
      this.loading = true
      this.$http
        .get('/boom-center-product-service/sysAdmin/subMerchant/page', {
          params: {
            businessId: this.$store.getters['vendor/params/getVendorId'],
            page: this.currentPage,
            size: this.pageSize
          }
        })
        .then(res => {
          this.tableData = res.list
          this.total = res.total
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.tableContainer {
  background-color: #fff;
  padding: @container-margin;
}
.settle {
  width: 30%;
  margin-right: 30px;
  display: inline-block;
}
.tip {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
}
.actions {
  text-align: right;
  margin-top: 50px;
  .outline {
    background: #fff;
    color: #e7141a;
  }
}
.wxCodeDataContainer {
  display: inline-block;
  text-align: center;
  vertical-align: top;
}
.accountValidation {
  display: inline-block;
  border-right: 1px solid #999;
  .item {
    margin: 10px 100px;
    display: flex;
    .des {
      width: 180px;
    }
  }
}
</style>
